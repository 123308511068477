import { styled } from "@mui/material";
import DatePickerField from "controls/global/datepicker";
import React from "react";
import { useFormState } from "react-hook-form";
import {
  borderSize,
  colors,
  // fontSize,
  // margin,
  // padding,
} from "theme/defaultStyle";
import useFormWrapper from "utils/custom-hooks/useFormWrapper";
import { getIn } from "utils/object";
// import Tooltip from "../ErrorTooltip";
import Tooltip from "@mui/material/Tooltip";

type Props = {
  pathToValidate: string;
  fullName: string;
  disabled: boolean;
};

const EffectiveDateWrapper = styled("div")({
  "& .MuiOutlinedInput-root": {
    height: "40px !important",
    border: "none",
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
  "& .MuiInputBase-root.MuiInput-root": {
    background: colors.white,
    border: "none",
    "&:hover": {
      border: "none",
    },
    "& input.date-picker-inline-custom": {
      background: "none",
    },
  },
  "& .MuiInputBase-root.MuiInput-root.Mui-error": {
    border: `${borderSize.xsmall} solid ${colors.red02} !important`,
    "&:hover": {
      border: "none",
    },
  },
  "& .MuiInputBase-root.Mui-error": {
    color: `${colors.red02}`,
    border: `${borderSize.xsmall} solid ${colors.red02} !important`,
  },  
  "& .MuiInputBase-root.MuiInput-root.Mui-disabled": {
    background: "none",
    border: "none",
    cursor: "not-allowed",
    "& input.date-picker-inline-custom": {
      cursor: "not-allowed",
    },
  },
  "& .MuiInputBase-root.MuiInput-root input[aria-invalid=true]": {
    color: colors.red02,
  },
  "& input.MuiInputBase-inputAdornedEnd": {
    paddingRight: "0px !important",
  },
  "& div.MuiInputAdornment-positionEnd": {
    marginLeft: "0px !important",
  },
});

// const EffectiveDateErrorMessage = styled("div")({
//   backgroundColor: colors.white,
//   color: colors.red02,
//   fontSize: fontSize.small,
//   padding: padding.xsmall,
//   margin: margin.zero,
// });

export default function EndorsementEffectiveDateCell({
  pathToValidate,
  fullName,
  disabled,
}: Props) {
  const [effectiveDateFocused, setEffectiveDateFocused] = React.useState(false);

  const formState = useFormState();
  const { trigger } = useFormWrapper();

  const errorMessage = getIn(formState.errors, fullName)?.message || "";
  const hasEffectiveDateError =
    effectiveDateFocused && errorMessage !== "Required" && errorMessage !== "";
  return (
    <Tooltip
      // content={
      //   <EffectiveDateErrorMessage
      //     dangerouslySetInnerHTML={{
      //       __html: errorMessage,
      //     }}
      //   />
      // }
      title={errorMessage}
      arrow // ={true}
      placement="top"
      // open={true} // hasEffectiveDateError}
    >
      <EffectiveDateWrapper>
          <DatePickerField
            label=""
            name={fullName}
            onFocusCallback={() => setEffectiveDateFocused(true)}
            onBlurCallback={() => {
              if (hasEffectiveDateError) trigger(pathToValidate);
              return setEffectiveDateFocused(false);
            }}
            size="small"
            noBorder={true}
            disabled={disabled}
            showError={false} // {true}
            validateOnBlur={false}
            errorMessageClassName="endorsementGridErrorMessage"
          />
      </EffectiveDateWrapper>
    </Tooltip>
  );
}
