import {
  Jacket,
  SCFile,
  StandaloneEndorsement,
} from "entities/UIModel";
import {
  PartyRoleType,
  PricingType,
} from "utils/data/enum";
import {
  convertToNumber,
  convertToNumberWithNegative,
  formatCurrency,
  REGEX_ALPHA_NUMERIC,
} from "utils/shared";
import * as yup from "yup";
import { isBefore } from "date-fns";
import { getRoundedValue } from "utils/services/utilsService";
import {
  getEndOfDay,
  VALID_ZIP_LENGTH,
  isForeignAddress,
  effectiveDateValidationMsg,
  isNullOrUndefined,
} from "../../helper";

const requiredErrorMessage: string = "Required";

const validationSchema = yup.object().shape({
  fileNameNumber: yup
    .string()
    .required(requiredErrorMessage)
    .test(
      "MustContainAlphaNumeric",
      "Must have at least one alphanumeric",
      (value, _) => {
        return value?.match(REGEX_ALPHA_NUMERIC) != null;
      }
    )
    .test(
      "isValidFilename",
      "Invalid file name: A valid file name is no more than 100 characters long",
      (value, _) => {
        return !(!value || value.length > 100);
      }
    )
    .test(
      "isDuplicatedFilename",
      " This File Name/Number already exists for this Agency.",
      (value, context) => {
        return !context.options.context?.isDuplicatedFile;
      }
    ),
  agency: yup.object({
    id: yup.string().required(requiredErrorMessage),
    name: yup.string(),
  }),
  agencyLocation: yup
    .object({
      id: yup.string(),
      name: yup.string(),
      isValidationRequired: yup.boolean(),
      isDisabled: yup.boolean(),
    })
    .test(
      "isAgencyLocationRequired",
      requiredErrorMessage,
      (value, context) => {
        return validateAgencyLocation(context) ? Boolean(value.name) : true;
      }
    )
    .test(
      "isAgencyLocationValid",
      "A supported Agency Location is needed. Please contact the Agency Support Center for assistance.",
      (value, context) => {
        return validateAgencyLocation(context)
          ? Boolean(value.name) && !value.isDisabled
          : true;
      }
    ),
  transactionTypeCode: yup.string().when("isTransactionTypeRequired", {
    is: true,
    then: yup.string().required(requiredErrorMessage),
  }),
  properties: yup.array().of(
    yup.object({
      addressOne: yup.string(),
      addressTwo: yup.string(),
      city: yup.string(),
      isValidationRequired: yup.boolean(),
      county: yup
        .object({
          code: yup.string(),
          name: yup.string(),
        })
        .test(
          "PropertyCountyRequired",
          requiredErrorMessage,
          (value, context) => {
            return validateProperties(context) ? Boolean(value.code) : true;
          }
        ),
      legalDescription: yup.string(),
      propertyType: yup.string(),
      state: yup
        .object({
          abbr: yup.string(),
          code: yup.string(),
        })
        .test(
          "PropertyStateRequired",
          requiredErrorMessage,
          (value, context) => {
            return validateProperties(context)
              ? Boolean(value.abbr && value.abbr !== "Select")
              : true;
          }
        ),
      zipCode: yup
        .string()
        .test(
          "isValidZip",
          "The zip code must be 5 or 9 numbers",
          (value, context) =>
            hasZipCodeValidation(context) ? isValidZipCode(value) : true
        ),
    })
  ),
  buyerBorrowerParties: yup.array().of(
    yup.object().shape({
      partyTypeCode: yup.string().required(),
      addressOne: yup.string().nullable(),
      addressTwo: yup.string().nullable(),
      city: yup.string().nullable(),
      state: yup
        .object({
          abbr: yup.string(),
          code: yup.string(),
        })
        .nullable(),
      zipCode: yup
        .string()
        .test(
          "isValidZip",
          "The zip code must be 5 or 9 numbers",
          (value, context) =>
            hasZipCodeValidation(context) ? isValidZipCode(value) : true
        ),
      suffixTypeCode: yup.string(),
      isExcludedOnCPL: yup.boolean(),
      partyFirstName: yup.string().when("requirePartyValidation", {
        is: true,
        then: yup
          .string()
          .when("partyTypeCode", (value: string, field: yup.StringSchema) =>
            getIndividualFieldRequired(value, field)
          ),
      }),
      partyMiddleName: yup.string(),
      partyLastName: yup.string().when("requirePartyValidation", {
        is: true,
        then: yup
          .string()
          .when("partyTypeCode", (value: string, field: yup.StringSchema) =>
            getIndividualFieldRequired(value, field)
          ),
      }),
      companyName: yup.string().when("requirePartyValidation", {
        is: true,
        then: yup
          .string()
          .when("partyTypeCode", (value: string, field: yup.StringSchema) =>
            getCompanyFieldRequired(value, field)
          ),
      }),
    })
  ),
  lenderParties: yup.array().of(
    yup.object().shape({
      partyTypeCode: yup.string().required(),
      addressOne: yup.string().nullable(),
      addressTwo: yup.string().nullable(),
      city: yup.string().nullable(),
      state: yup
        .object({
          abbr: yup.string(),
          code: yup.string(),
        })
        .nullable(),
      zipCode: yup
        .string()
        .test(
          "isValidZip",
          "The zip code must be 5 or 9 numbers",
          (value, context) =>
            hasZipCodeValidation(context) ? isValidZipCode(value) : true
        )
        .nullable(),
      suffixTypeCode: yup.string(),
      isExcludedOnCPL: yup.boolean(),
      companyName: yup
        .string()
        .when(
          "requirePartyValidation",
          (value: boolean, field: yup.StringSchema) =>
            getAddressFieldRequired(value, field)
        ),
    })
  ),
  sellerParties: yup.array().of(
    yup.object().shape({
      partyTypeCode: yup.string().required(),
      addressOne: yup.string().nullable(),
      addressTwo: yup.string().nullable(),
      city: yup.string().nullable(),
      state: yup
        .object({
          abbr: yup.string(),
          code: yup.string(),
        })
        .nullable(),
      zipCode: yup
        .string()
        .test(
          "isValidZip",
          "The zip code must be 5 or 9 numbers",
          (value, context) =>
            hasZipCodeValidation(context) ? isValidZipCode(value) : true
        ),
      suffixTypeCode: yup.string(),
      isExcludedOnCPL: yup.boolean(),
      partyFirstName: yup.string(),
      partyMiddleName: yup.string(),
      partyLastName: yup.string(),
      companyName: yup.string(),
    })
  ),
  cpls: yup.array().of(
    yup.object({
      isValidationRequired: yup.boolean(),
      addresseeNames: yup
        .string()
        .nullable()
        .when("isValidationRequired", {
          is: true,
          then: yup
            .string()
            .nullable()
            .test("", requiredErrorMessage, (value, context) => {
              if (
                context.parent &&
                context.parent.addresseeNames &&
                context.parent.addresseeNames.trim().length > 0
              ) {
                return true;
              }

              return false;
            }),
        }),
      coveredParty: yup.string().nullable(),
      effectiveDate: yup
        .date()
        .nullable()
        //.required(requiredErrorMessage)
        .when("isValidationRequired", {
          is: true,
          then: yup
            .date()
            .nullable()
            .test("", requiredErrorMessage, (value, context) => {
              return !!value;
            })
            .test({
              name: "Validating effective date",
              test: function (value, context) {
                const contextValues = getValuesFromContext(context);
                const index = getIndexFromPath(this.path);
                const msg = effectiveDateValidationMsg(
                  index,
                  contextValues?.cpls
                );
                return msg ? context.createError({ message: msg }) : true;
              },
            }),
        }),
      form: yup
        .string()
        .nullable()
        .when("isValidationRequired", {
          is: true,
          then: yup
            .string()
            .nullable()
            .test("", requiredErrorMessage, (value, context) => {
              if (value && value.length > 0) return true;
              return false;
            }),
        }),
      pricingDetail: yup.object({
        locationDisplayName: yup
          .string()
          .nullable()
          .when("isValidatingLocationRequired", {
            is: true,
            then: yup
              .string()
              .nullable()
              .test(
                "locationRequired",
                requiredErrorMessage,
                (value, context) => {
                  if (value && value.length > 0) return true;
                  else return false;
                }
              )
              .test(
                "validateLocation",
                "Invalid Agency Location",
                (value, context) => {
                  return validateProductAgencyLocation(value || "", context);
                }
              ),
          }),
      }),
      includeAllBranches: yup.bool(),
      addressOne: yup
        .string()
        .nullable()
        .when("isValidationRequired", {
          is: true,
          then: yup
            .string()
            .nullable()
            .test("", requiredErrorMessage, (value, context) => {
              return validateStringField(value || "");
            }),
        }),
      addressTwo: yup.string().nullable(),
      city: yup
        .string()
        .nullable()
        .when("isValidationRequired", {
          is: true,
          then: yup
            .string()
            .nullable()
            .test("", requiredErrorMessage, function (value, context) {
              const contextValues = getValuesFromContext(context);
              const index = parseInt(this.path.split("[")[1].split("]")[0], 10);
              if (isForeignAddress(index, contextValues.cpls)) return true;
              return validateStringField(value || "");
            }),
        }),
      state: yup
        .object({
          code: yup.string(),
          abbr: yup
            .string()
            .test(
              "State Required",
              requiredErrorMessage,
              function (value, context) {
                const contextValues = getValuesFromContext(context);
                const index = parseInt(
                  this.path.split("[")[1].split("]")[0],
                  10
                );
                if (isForeignAddress(index, contextValues.cpls)) return true;

                const values: any[] = (context as any).from;
                if (values && values.length >= 2) {
                  const root = values[values.length - 2].value;
                  if (root.isValidationRequired && !context.parent.code)
                    return false;
                }
                return true;
              }
            ),
        })
        .nullable(),
      zipCode: yup
        .string()
        .nullable()
        .when("isValidationRequired", {
          is: true,
          then: yup
            .string()
            .nullable()
            .test("", requiredErrorMessage, function (value, context) {
              const contextValues = getValuesFromContext(context);
              const index = parseInt(this.path.split("[")[1].split("]")[0], 10);
              if (isForeignAddress(index, contextValues.cpls)) return true;
              return validateStringField(value || "");
            }),
        }),
      foreignCountry: yup
        .string()
        .nullable()
        .when("isValidationRequired", {
          is: true,
          then: yup
            .string()
            .nullable()
            .test("", requiredErrorMessage, function (value, context) {
              const contextValues = getValuesFromContext(context);
              const index = parseInt(this.path.split("[")[1].split("]")[0], 10);
              if (!isForeignAddress(index, contextValues.cpls)) return true;
              return validateStringField(value || "");
            }),
        }),
      buyerBorrower: yup
        .string()
        .nullable()
        .when("isValidationRequired", {
          is: true,
          then: yup
            .string()
            .nullable()
            .test("", requiredErrorMessage, (value, context) => {
              if (
                context.parent &&
                context.parent.buyerBorrower &&
                context.parent.buyerBorrower.trim().length > 0
              ) {
                return true;
              }

              return false;
            }),
        }),

      seller: yup
        .string()
        .nullable()
        .when("isValidationRequired", {
          is: true,
          then: yup
            .string()
            .nullable()
            .test("", requiredErrorMessage, (value, context) => {
              if (
                context.parent &&
                context.parent.coveredParty &&
                context.parent.coveredParty.toUpperCase() !==
                  PartyRoleType.Seller
              )
                return true;
              if (
                context.parent &&
                context.parent.seller &&
                context.parent.seller.trim().length > 0
              ) {
                return true;
              }

              return false;
            }),
        }),
    })
  ),
  aALProducts: yup.array().of(
    yup.object({
      isValidationRequired: yup.boolean(),
      addresseeNames: yup
        .string()
        .nullable()
        .when("isValidationRequired", {
          is: true,
          then: yup
            .string()
            .nullable()
            .test("", requiredErrorMessage, (value, context) => {
              if (
                context.parent &&
                context.parent.addresseeNames &&
                context.parent.addresseeNames.trim().length > 0
              ) {
                return true;
              }

              return false;
            }),
        }),
      coveredParty: yup.string().nullable(),
      effectiveDate: yup
        .date()
        .nullable()
        .when("isValidationRequired", {
          is: true,
          then: yup
            .date()
            .nullable()
            .test("", requiredErrorMessage, (value, context) => {
              return !!value;
            })
            .test({
              name: "Validating effective date",
              test: function (value, context) {
                const contextValues = getValuesFromContext(context);
                const index = getIndexFromPath(this.path);
                const msg = effectiveDateValidationMsg(
                  index,
                  contextValues?.aALProducts
                );
                return msg ? context.createError({ message: msg }) : true;
              },
            }),
        }),
      form: yup
        .string()
        .nullable()
        .when("isValidationRequired", {
          is: true,
          then: yup
            .string()
            .nullable()
            .test("", requiredErrorMessage, (value, context) => {
              if (value && value.length > 0) return true;
              return false;
            }),
        }),
      pricingDetail: yup.object({
        locationDisplayName: yup
          .string()
          .nullable()
          .when("isValidatingLocationRequired", {
            is: true,
            then: yup
              .string()
              .nullable()
              .test(
                "locationRequired",
                requiredErrorMessage,
                (value, context) => {
                  if (value && value.length > 0) return true;
                  else return false;
                }
              )
              .test(
                "validateLocation",
                "Invalid Agency Location",
                (value, context) => {
                  return validateProductAgencyLocation(value || "", context);
                }
              ),
          }),
      }),
      includeAllBranches: yup.bool(),
      addressOne: yup
        .string()
        .nullable()
        .when("isValidationRequired", {
          is: true,
          then: yup
            .string()
            .nullable()
            .test("", requiredErrorMessage, (value, context) => {
              return validateStringField(value || "");
            }),
        }),
      addressTwo: yup.string().nullable(),
      city: yup
        .string()
        .nullable()
        .when("isValidationRequired", {
          is: true,
          then: yup
            .string()
            .nullable()
            .test("", requiredErrorMessage, function (value, context) {
              const contextValues = getValuesFromContext(context);
              const index = parseInt(this.path.split("[")[1].split("]")[0], 10);
              if (isForeignAddress(index, contextValues.aALProducts))
                return true;
              return validateStringField(value || "");
            }),
        }),
      state: yup
        .object({
          code: yup.string(),
          abbr: yup
            .string()
            .test(
              "State Required",
              requiredErrorMessage,
              function (value, context) {
                const contextValues = getValuesFromContext(context);
                const index = parseInt(
                  this.path.split("[")[1].split("]")[0],
                  10
                );
                if (isForeignAddress(index, contextValues.aALProducts))
                  return true;

                const values: any[] = (context as any).from;
                if (values && values.length >= 2) {
                  const root = values[values.length - 2].value;
                  if (root.isValidationRequired && !context.parent.code)
                    return false;
                }
                return true;
              }
            ),
        })
        .nullable(),
      zipCode: yup
        .string()
        .nullable()
        .when("isValidationRequired", {
          is: true,
          then: yup
            .string()
            .nullable()
            .test("", requiredErrorMessage, function (value, context) {
              const contextValues = getValuesFromContext(context);
              const index = parseInt(this.path.split("[")[1].split("]")[0], 10);
              if (isForeignAddress(index, contextValues.aALProducts))
                return true;
              return validateStringField(value || "");
            }),
        }),
      foreignCountry: yup
        .string()
        .nullable()
        .when("isValidationRequired", {
          is: true,
          then: yup
            .string()
            .nullable()
            .test("", requiredErrorMessage, function (value, context) {
              const contextValues = getValuesFromContext(context);
              const index = parseInt(this.path.split("[")[1].split("]")[0], 10);
              if (!isForeignAddress(index, contextValues.aALProducts))
                return true;
              return validateStringField(value || "");
            }),
        }),
      buyerBorrower: yup
        .string()
        .nullable()
        .when("isValidationRequired", {
          is: true,
          then: yup
            .string()
            .nullable()
            .test("", requiredErrorMessage, (value, context) => {
              if (
                context.parent &&
                context.parent.buyerBorrower &&
                context.parent.buyerBorrower.trim().length > 0
              ) {
                return true;
              }

              return false;
            }),
        }),
    })
  ),
  jackets: yup.array().of(
    yup.object().shape({
      effectiveDate: yup
        .date()
        .nullable()
        .when("isValidationRequired", {
          is: true,
          then: yup
            .date()
            .nullable()
            .required(requiredErrorMessage)
            .test({
              name: "Validating effective date",
              test: function (value, context) {
                const contextValues = getValuesFromContext(context);
                const index = getIndexFromPath(this.path);
                const msg = effectiveDateValidationMsg(
                  index,
                  contextValues?.jackets
                );
                return msg ? context.createError({ message: msg }) : true;
              },
            }),
        }),
      form: yup
        .string()
        .nullable()
        .when("isValidationRequired", {
          is: true,
          then: yup
            .string()
            .nullable()
            .test({
              name: "Validating form based on effective date",
              test: function (value, context) {
                const contextValues = getValuesFromContext(context);
                const index = getIndexFromPath(this.path);
                if (!contextValues?.jackets) return true;
                const msg = contextValues?.jackets[index].formValidationMsg;
                return msg ? context.createError({ message: msg }) : true;
              },
            }),
        }),
      pricingDetail: yup.object({
        transCodeKey: yup
          .string()
          .nullable()
          .when("isValidationRequired", {
            is: true,
            then: yup
              .string()
              .nullable()
              .test("", requiredErrorMessage, (value, context) => {
                const contextFrom: any[] = (context as any).from;
                const jacketRoot = contextFrom[contextFrom.length - 2].value;

                if (jacketRoot && jacketRoot.isTransCodeRequired) {
                  return Boolean(value);
                }

                return true;
              }),
          }),
        liability: yup.string().when("isValidationRequired", {
          is: true,
          then: yup
            .string()
            .test("", requiredErrorMessage, (value) => {
              return getRoundedValue(value) > 0;
            })
            .test("validateLiability", "", function (value, context) {
              const contextValues = getValuesFromContext(context);
              const liabilityConfig = getLiabilityConfig(context);

              if (!liabilityConfig || !liabilityConfig.configValue) return true;

              const index = parseInt(this.path.split("[")[1].split("]")[0], 10);
              const booleanFormValue =
                contextValues.jackets[index].form === liabilityConfig.form;
              if (
                booleanFormValue &&
                getRoundedValue(value) > parseInt(liabilityConfig.configValue)
              ) {
                return this.createError({
                  message: `Liability amount cannot exceed ${formatCurrency(
                    parseInt(liabilityConfig.configValue)
                  )}`,
                });
              }

              return true;
            }),
        }),
        locationDisplayName: yup
          .string()
          .nullable()
          .when("isValidatingLocationRequired", {
            is: true,
            then: yup
              .string()
              .nullable()
              .test(
                "locationRequired",
                requiredErrorMessage,
                (value, context) => {
                  if (value && value.length > 0) return true;
                  else return false;
                }
              )
              .test(
                "validateLocation",
                "Invalid Agency Location",
                (value, context) => {
                  return validateProductAgencyLocation(value || "", context);
                }
              ),
          }),
      }),
      /* TODO: Remove this section after business confirms this should for sure be completely removed */
      /*
      originalJacketDate: yup
        .date()
        .nullable()
        .test("", requiredErrorMessage, (value, context) => {
          if (!shouldValidateJacketOPN(context)) return true;
          const isRequired =
            context.parent.originalJacketLiability ||
            context.parent.originalJacketNumber ||
            context.parent.originalJacketType;

          return isRequired ? !!value : true;
        }),
      originalJacketLiability: yup
        .number()
        .nullable()
        .test("", requiredErrorMessage, (value, context) => {
          if (!shouldValidateJacketOPN(context)) return true;
          const isRequired =
            context.parent.originalJacketDate ||
            context.parent.originalJacketNumber ||
            context.parent.originalJacketType;

          return isRequired ? !!value : true;
        }),
      originalJacketNumber: yup
        .string()
        .nullable()
        .test("", requiredErrorMessage, (value, context) => {
          if (!shouldValidateJacketOPN(context)) return true;

          const isRequired =
            context.parent.originalJacketLiability ||
            context.parent.originalJacketDate ||
            context.parent.originalJacketType;

          return isRequired ? !!value : true;
        }),
      originalJacketType: yup
        .string()
        .nullable()
        .test("", requiredErrorMessage, (value, context) => {
          if (!shouldValidateJacketOPN(context)) return true;
          const isRequired =
            context.parent.originalJacketLiability ||
            context.parent.originalJacketNumber ||
            context.parent.originalJacketDate;

          return isRequired ? !!value : true;
        }),*/
      endorsements: yup.array().of(
        yup.object({
          effectiveDate: yup
            .date()
            .nullable()
            .test(
              "if the endorsement is required",
              requiredErrorMessage,
              (value, context) => isValidEndorsement(context) || Boolean(value)
            )
            .test(
              "equal to or greater than jacket effective date",
              // "The effective date must be equal to or<br />greater than the Jacket Effective Date",
              "Must be the same or greater than Jacket Effective Date",
              (value, context) => {
                if (isValidEndorsement(context)) return true;

                const jacket: Jacket = (context as any).from[1].value;
                jacket.effectiveDate?.setHours(0, 0, 0, 0);

                return !isBefore(value as Date, jacket.effectiveDate!!);
              }
            ), // eo effectivedate
          pricingDetail: yup.object({
            transCodeKey: yup
              .string()
              .nullable()
              .test("", requiredErrorMessage, (value, context) => {
                const contextFrom: any[] = (context as any).from;
                const jacketRoot = contextFrom[contextFrom.length - 2].value;
                const endo = contextFrom[contextFrom.length - 3].value;

                if (jacketRoot && jacketRoot.isTransCodeRequired) {
                  if (endo.isSFE) {
                    if (endo.isSelected && !value) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                  return Boolean(value);
                }

                return true;
              }),
          }), //eof pricing detail
        }) // eof yup object
      ),
    })
  ),
  standaloneEndorsements: yup.array().of(
    yup.object().shape({
      // originalJacketDate: yup
      //   // .date()
      //   .string()
      //   .nullable()
      //   .when("isValidationRequired", {
      //     is: true,
      //     then: yup
      //       // .date()
      //       .string()
      //       .nullable()
      //       .required(requiredErrorMessage)
      //       .test("Must be a valid date", "Must be a valid date", (value) => {
      //         return isValidDate(new Date(value as string));
      //       })
      //     .test("Must be greter than today", "Original Policy Date cannot be future date", (value) => {
      //       const currentDate = (new Date()).setHours(0,0,0,0);
      //       console.log('currentDate:', currentDate);
      //       console.log('input date:', value);
      //       return isBefore((new Date(value as string)).setHours(0,0,0,0), currentDate);
      //     }),
      //   }), // eof originalJacketDate
      pricingDetail: yup.object({
        locationDisplayName: yup
          .string()
          .nullable()
          .when("isValidatingLocationRequired", {
            is: true,
            then: yup
              .string()
              .nullable()
              .test(
                "locationRequired",
                requiredErrorMessage,
                (value, context) => {
                  if (value && value.length > 0) return true;
                  else return false;
                }
              )
              .test(
                "validateLocation",
                "Invalid Agency Location",
                (value, context) => {
                  return validateProductAgencyLocation(value || "", context);
                }
              ),
          }),
      }),
      endorsements: yup.array().of(
        yup.object({
          effectiveDate: yup
            .string()
            .nullable()
            .when("isValidationRequired", {
              is: true,
              then: yup
                .string()
                .nullable()
                .test("Required", requiredErrorMessage, (value, context) => {
                  return (
                    isIssuable((context as any).from[1].value) &&
                    !isNullOrUndefined(value)
                  );
                })
                .test(
                  "Must be a valid date",
                  "Must be a valid date",
                  (value) => {
                    return isValidDate(new Date(value as string));
                  }
                )
                .test(
                  "equal to or greater than jacket effective date",
                  // "The effective date must be equal to or<br />greater than the Original Policy Date",
                  "Must be the same or greater than Original Policy Date",
                  (value, context) => {
                    const standaloneEndorsement: StandaloneEndorsement = (
                      context as any
                    ).from[1].value;

                    standaloneEndorsement.originalJacketDate?.setHours(
                      0,
                      0,
                      0,
                      0
                    );

                    return (
                      !isIssuable(standaloneEndorsement) ||
                      !isBefore(
                        new Date(value as string),
                        standaloneEndorsement.originalJacketDate!!
                      )
                    );
                  }
                ),
            }), // eof effectivedate
          pricingDetail: yup.object({
            transCodeKey: yup
              .string()
              .nullable()
              .test("", requiredErrorMessage, (value, context) => {
                const contextFrom: any[] = (context as any).from;
                // console.log('contextFrom: ', contextFrom);
                const productRoot = contextFrom[contextFrom.length - 2].value;

                if (productRoot && productRoot.isTransCodeRequired) {
                  return Boolean(value);
                }
                return true;
              }),
          }), //eof pricing detail
        })
      ),
    })
  ),
  pricing: yup.object({
    isValidationRequired: yup.boolean(),
    coInsurancePct: yup
      .string()
      .nullable()
      .when("isValidationRequired", {
        is: true,
        then: yup
          .string()
          .test(
            "coInsurancePct",
            "Co-Insurance must be between 0 and 100",
            (value, context) => {
              const root = getValuesFromContext(context);
              if (!root.pricing.isCoInsurance) return true;
              const valueNumber = convertToNumberWithNegative(value);
              return valueNumber >= 0 && valueNumber <= 100;
            }
          ),
      }),
  }),
  pricingProducts: yup.array().of(
    yup.object({
      isValidationRequired: yup.boolean(),
      actualFee: yup.string().when("isValidationRequired", {
        is: true,
        then: yup.string().when("pricingType", {
          is: (pricingType: string) => pricingType === PricingType.Tax,
          then: yup
            .string()
            .test(
              "taxAmount",
              "Tax Amount cannot exceed Product Premiums",
              (value, context) => {
                const tax = convertToNumber(value);
                const totalProductPremium = convertToNumber(
                  context.parent.totalProductPremium
                );
                return tax <= totalProductPremium;
              }
            ),
        }),
      }),

      actualRiskRate: yup.string().when("isValidationRequired", {
        is: true,
        then: yup
          .string()
          .test(
            "actualRiskRate",
            "Risk Rate cannot exceed Premium/Fee",
            (value, context) => {
              if (!showRiskRate(context)) return true;
              const valueNumber = convertToNumber(value);
              const fee = convertToNumber(context.parent.actualFee);
              return fee >= 0 ? valueNumber <= fee : valueNumber >= fee;
            }
          )
          .test(
            "actualRiskRate",
            "Risk Rate cannot be negative",
            (value, context) => {
              if (!showRiskRate(context)) return true;
              const valueNumber = convertToNumber(value);
              return valueNumber >= 0;
            }
          ),
      }),

      agentRetention: yup.string().when("isValidationRequired", {
        is: true,
        then: yup
          .string()
          .test(
            "agentRetention",
            "Agent Retention cannot exceed Premium/Fee",
            (value, context) => {
              if (showRiskRate(context)) return true;
              const valueNumber = convertToNumber(value);
              const fee = convertToNumber(context.parent.actualFee);
              return fee >= 0 ? valueNumber <= fee : valueNumber >= fee;
            }
          )
          .test(
            "agentRetention",
            "Agent Retention cannot exceed Risk Rate",
            (value, context) => {
              if (!showRiskRate(context)) return true;
              const valueNumber = convertToNumber(value);
              const fee = convertToNumber(context.parent.actualRiskRate);
              return fee >= 0 ? valueNumber <= fee : valueNumber >= fee;
            }
          ),
      }),

      totalDue: yup.string().when("isValidationRequired", {
        is: true,
        then: yup
          .string()
          .test(
            "totalDue",
            "Total Due cannot exceed Premium/Fee",
            (value, context) => {
              if (showRiskRate(context)) return true;
              const valueNumber = convertToNumber(value);
              const fee = convertToNumber(context.parent.actualFee);
              return fee >= 0 ? valueNumber <= fee : valueNumber >= fee;
            }
          )
          .test(
            "totalDue",
            "Total Due cannot exceed Risk Rate",
            (value, context) => {
              if (!showRiskRate(context)) return true;
              const valueNumber = convertToNumber(value);
              const fee = convertToNumber(context.parent.actualRiskRate);
              return fee >= 0 ? valueNumber <= fee : valueNumber >= fee;
            }
          ),
      }),

      productItems: yup.array().of(
        yup.object({
          isValidationRequired: yup.boolean(),
          actualFee: yup.string(),
          actualRiskRate: yup.string().when("isValidationRequired", {
            is: true,
            then: yup
              .string()
              .test(
                "actualRiskRateItem",
                "Risk Rate cannot exceed Premium/Fee",
                (value, context) => {
                  if (!showRiskRate(context)) return true;
                  const valueNumber = convertToNumber(value);
                  const fee = convertToNumber(context.parent.actualFee);
                  return fee >= 0 ? valueNumber <= fee : valueNumber >= fee;
                }
              )
              .test(
                "actualRiskRateItem",
                "Risk Rate cannot be negative",
                (value, context) => {
                  if (!showRiskRate(context)) return true;
                  const valueNumber = convertToNumber(value);
                  return valueNumber >= 0;
                }
              ),
          }),

          agentRetention: yup.string().when("isValidationRequired", {
            is: true,
            then: yup
              .string()
              .test(
                "agentRetentionItem",
                "Agent Retention cannot exceed Premium/Fee",
                (value, context) => {
                  if (showRiskRate(context)) return true;
                  const valueNumber = convertToNumber(value);
                  const fee = convertToNumber(context.parent.actualFee);
                  return fee >= 0 ? valueNumber <= fee : valueNumber >= fee;
                }
              )
              .test(
                "agentRetentionItem",
                "Agent Retention cannot exceed Risk Rate",
                (value, context) => {
                  if (!showRiskRate(context)) return true;
                  const valueNumber = convertToNumber(value);
                  const fee = convertToNumber(context.parent.actualRiskRate);
                  return fee >= 0 ? valueNumber <= fee : valueNumber >= fee;
                }
              ),
          }),

          totalDue: yup.string().when("isValidationRequired", {
            is: true,
            then: yup
              .string()
              .test(
                "totalDueItem",
                "Total Due cannot exceed Premium/Fee",
                (value, context) => {
                  if (showRiskRate(context)) return true;
                  const valueNumber = convertToNumber(value);
                  const fee = convertToNumber(context.parent.actualFee);
                  return fee >= 0 ? valueNumber <= fee : valueNumber >= fee;
                }
              )
              .test(
                "totalDueItem",
                "Total Due cannot exceed Risk Rate",
                (value, context) => {
                  if (!showRiskRate(context)) return true;
                  const valueNumber = convertToNumber(value);
                  const fee = convertToNumber(context.parent.actualRiskRate);
                  return fee >= 0 ? valueNumber <= fee : valueNumber >= fee;
                }
              ),
          }),
        })
      ),
      originalPolicy: yup.object({
        isValidationRequired: yup.boolean(),
        date: yup.date().when("isValidationRequired", {
          is: true,
          then: yup
            .date()
            .nullable()
            .required(requiredErrorMessage)
            .max(
              getEndOfDay(new Date()),
              "Policy Date cannot be in the future"
            ),
        }),
        coverageType: yup
          .string()
          .nullable()
          .test("coverageType", requiredErrorMessage, (value, context) =>
            shouldValidateCoverageType(context) ? !!value : true
          ),
        liabilityAmount: yup.string().when("isValidationRequired", {
          is: true,
          then: yup
            .string()
            .test(
              "liabilityAmount must be a number",
              requiredErrorMessage,
              (value) => getRoundedValue(value) > 0
            ),
        }),
      }),
    })
  ),
});

const getIndividualFieldRequired = (value: string, field: yup.StringSchema) => {
  switch (value) {
    case "INDIVIDUAL":
    case "COUPLE":
      return field.required(requiredErrorMessage);
    default:
      return field;
  }
};

const getCompanyFieldRequired = (value: string, field: yup.StringSchema) => {
  switch (value) {
    case "INDIVIDUAL":
    case "COUPLE":
      return field;
    default:
      return field.required(requiredErrorMessage);
  }
};

const getAddressFieldRequired = (value: boolean, field: yup.StringSchema) => {
  if (value && value === true) {
    return field.required(requiredErrorMessage);
  } else {
    return field;
  }
};

const validateStringField = (value: string) => {
  return !!(value && value.trim().length > 0);
};

const getIndexFromPath = (path: string): number =>
  parseInt(path.split("[")[1].split("]")[0], 10);

const getValuesFromContext = (
  context: yup.TestContext<Record<string, any>>
): SCFile => {
  const contextFrom = (context as any).from;
  const values = contextFrom[contextFrom.length - 1].value;
  return values;
};
const getLiabilityConfig = (
  context: yup.TestContext<Record<string, any>>
): { form?: string; configValue?: string; } => {
  const contextPricinConfig = (context as any).options.context.liabilityConfig;
  return contextPricinConfig;
};

const validateAgencyLocation = (
  context: yup.TestContext<Record<string, any>>
): boolean => {
  const root = getValuesFromContext(context);
  const isEnabled = Boolean(
    root.fileNameNumber &&
    root.agency.id &&
    (root.agencyLocation.isValidationRequired ||
      root.agencyLocation.isDisabled)
  );
  return isEnabled;
};

const validateProductAgencyLocation = (
  value: string,
  context: yup.TestContext<Record<string, any>>
): boolean => {
  const root = getValuesFromContext(context);
  const foundLocation = root.validLocations?.find(
    (location) => location.locationDisplayName === value
  );
  return !!foundLocation;
};

const validateProperties = (
  context: yup.TestContext<Record<string, any>>
): boolean => {
  const root = getValuesFromContext(context);
  const isEnabled = Boolean(
    root.fileNameNumber && root.agency.id && root.isPropertiesValidationRequired
  );
  return isEnabled;
};

function isIssuable(standaloneEndorsement: StandaloneEndorsement): boolean {
  return (
    !isNullOrUndefined(standaloneEndorsement.originalJacketNumber) &&
    !isNullOrUndefined(standaloneEndorsement.originalJacketDate) &&
    !isNullOrUndefined(standaloneEndorsement.originalJacketType) &&
    !isNullOrUndefined(standaloneEndorsement.originalJacketLiability) &&
    !isNullOrUndefined(standaloneEndorsement.originalJacketLiability) &&
    standaloneEndorsement.originalJacketLiability!.toString().length > 0
  );
}

const showRiskRate = (
  context: yup.TestContext<Record<string, any>>
): boolean => {
  const root = getValuesFromContext(context);
  return root.pricing.showRiskRate;
};
/* TODO: Remove this section after business confirms this should for sure be completely removed */
/*
const shouldValidateJacketOPN = (
  context: yup.TestContext<Record<string, any>>
): boolean => {
  const root = getValuesFromContext(context);
  const isValid =
    context.parent.isValidationRequired &&
    !root.pricing.isIntegratedPricing &&
    context.parent.pricingDetail.isReissue;
  return isValid;
};
*/

const shouldValidateCoverageType = (
  context: yup.TestContext<Record<string, any>>
): boolean => {
  const root = getValuesFromContext(context);
  return context.parent.isValidationRequired &&
    root.pricing.isIntegratedPricing;
};

const isValidDate = (date: Date | null) =>
  Boolean(date && !isNaN(date.getTime()));

const isValidZipCode = (code?: string) => {
  if (!code || code === "") return true;
  return VALID_ZIP_LENGTH.includes(code.length);
};

const hasZipCodeValidation = (
  context: yup.TestContext<Record<string, any>>
): boolean => {
  const root = getValuesFromContext(context);
  return Boolean(root.isZipCodeValidationRequired);
};

const isValidEndorsement = ({
  parent,
}: yup.TestContext<Record<string, any>>): boolean =>
  !parent.isValidationRequired ||
  (parent.isSFE && !parent.isSelected) ||
  (!parent.isSFE && !parent.endorsementName && !parent.effectiveDate);

export default validationSchema;
